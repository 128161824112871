
$max-width: 1280px;
$margin: 40px; // base margin for the screen edges on screen sizes lower than $max-width
$marginMobile: 17px;

$brMobile: new-breakpoint(max-width 480px 4);
$brTablet: new-breakpoint(max-width 780px 6);
$brDesktopMin: new-breakpoint(min-width 1080px 12);
$brDesktop: new-breakpoint(max-width 1080px 12);
$brLargeDesktop: new-breakpoint(max-width 1380px 12);

$imgDir: "../images";

$color: (
        #8E3133, // 1 main color, usually the main color of the logo, used for default button bg, links, active menu items
        #551f21, // 2 usually default button hover color
        #515256, // 3 .alt.button bg
        #9f9f9f, // 4 .alt.button:hover bg
        #fd8100, // 5 .strong.button bg
        #d45427, // 6 .strong.button:hover bg
        #454545, // 7 .post-content color
        #7a7a7a, // 8 borders, footer font color
        #ddd,    // 9 borders
        #f0f0f0  // 10 borders, light section bgs etc
);

$mainBg: #fff;
$headerBg: rgba(#fff, .95);
$footerBg: #ccc;

/* BASE SETTINGS
================================================================================================ */
//$base-font-family: "Open Sans", sans-serif;
//$heading-font-family: "Raleway", sans-serif;
$base-font-family: 'Arbutus Slab', serif;
$heading-font-family: 'Kadwa', serif;
$base-font-color: nth($color, 7);
$em-base: 16px;

// Line height
$base-line-height: 1.25;
$heading-line-height: 1.2;

// Other Sizes
$base-border-radius: 0;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

$fa-font-path: "../css/fonts";
$ionicons-font-path: "../css/fonts";

$buttons-list: 'button',
'input[type="button"]',
'input[type="reset"]',
'input[type="submit"]',
'a.button',
'button.button',
'button.button.alt',
'button.button.alt.disabled',
'a.button',
'a.button.alt',
'a.button.disabled',
'a.button.alt.disabled';

$all-buttons: assign-inputs($buttons-list);
$all-buttons-active: assign-inputs($buttons-list, active);
$all-buttons-focus: assign-inputs($buttons-list, focus);
$all-buttons-hover: assign-inputs($buttons-list, hover);